import React, { useEffect } from 'react';
import { Link as MuiLink, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import HelpIcon from '@mui/icons-material/Help';
import StarIcon from '@mui/icons-material/Star';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import './../../../../App.css'


const smoothScroll = (target: HTMLElement) =>
{
    const targetPosition = target.getBoundingClientRect().top + window.scrollY - 75;
    const startPosition = window.scrollY;
    const distance = targetPosition - startPosition;
    const duration = 1000;
    let start: number | null = null;

    const easeInOutCubic = (t: number) =>
    {
        return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
    };

    const step = (timestamp: number) =>
    {
        if (!start) start = timestamp;
        const progress = timestamp - start;
        const percent = Math.min(progress / duration, 1);
        const easedPercent = easeInOutCubic(percent);
        window.scrollTo(0, startPosition + distance * easedPercent);
        if (progress < duration)
        {
            requestAnimationFrame(step);
        }
    };

    requestAnimationFrame(step);
};


interface NavBarProps
{
    heroRef: React.RefObject<HTMLDivElement>;
    departmentsRef: React.RefObject<HTMLDivElement>;
    leadershipRef: React.RefObject<HTMLDivElement>;
    eventsRef: React.RefObject<HTMLDivElement>;
    sponsorsRef: React.RefObject<HTMLDivElement>;
    faqRef: React.RefObject<HTMLDivElement>;
    contactRef: React.RefObject<HTMLDivElement>;
}

const NavBar: React.FC<NavBarProps> = ({
    heroRef,
    departmentsRef,
    leadershipRef,
    eventsRef,
    sponsorsRef,
    faqRef,
    contactRef

}) =>
{
    const location = useLocation();
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    useEffect(() =>
    {
        console.log(location.pathname)
    })
    const scrollToSection = (ref: React.RefObject<HTMLDivElement>) =>
    {
        if (ref.current)
        {
            smoothScroll(ref.current);
        }
    };

    return (
        <Stack
            sx={{
                zIndex: '100',
                position: 'fixed',
                top: 0,
                left: 0,
                display: 'flex',
                flexDirection: 'row',
                background: 'linear-gradient(45deg, #D0EFFF66 0%, #1167B166 100%) ',
                backdropFilter: 'blur(4px)',
                borderRadius: '0 0 120px 120px',
                borderBottom: '2px solid #D0EFFF99',
                borderRight: '2px solid #D0EFFF99',
                borderLeft: '2px solid #D0EFFF99',
                width: '100vw',
                alignItems: 'flex-start',
                justifyContent: 'center',
                paddingY: '16px',
                gap: {
                    xs: '16px',
                    md: '32px',
                },
                maxHeight: '57px',
                overflowX: 'auto',
                '>*': {
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    flexDirection: 'column',
                    '&::after': {
                        content: '""',
                        background: '#03254C',
                        width: '0%',
                        borderRadius: '2px',
                        height: '3px',
                        transition: 'all 0.2s ease-in-out',
                    },
                    '&:hover': {
                        '&::after': {
                            width: '100%',
                        },
                    },
                },
            }}
        >
            {location.pathname !== '/faq' && (
                isMdUp ? (
                    <>
                        <MuiLink onClick={() => scrollToSection(heroRef)}>acasa</MuiLink>
                        <MuiLink onClick={() => scrollToSection(departmentsRef)}>departamente</MuiLink>
                        <MuiLink onClick={() => scrollToSection(eventsRef)}>evenimente</MuiLink>
                        <MuiLink onClick={() => scrollToSection(leadershipRef)}>conducere</MuiLink>
                        <MuiLink onClick={() => scrollToSection(sponsorsRef)}>sponsori</MuiLink>
                        <MuiLink onClick={() => scrollToSection(contactRef)}>contact</MuiLink>
                        <RouterLink to="/faq"><MuiLink>faq</MuiLink></RouterLink>
                    </>
                ) : (
                    <>
                    <MuiLink onClick={() => scrollToSection(heroRef)}><HomeIcon /></MuiLink>
                    <MuiLink onClick={() => scrollToSection(departmentsRef)}><BusinessIcon /></MuiLink>
                    <MuiLink onClick={() => scrollToSection(eventsRef)}><EventIcon /></MuiLink>
                    <MuiLink onClick={() => scrollToSection(leadershipRef)}><PeopleIcon /></MuiLink>
                    <MuiLink onClick={() => scrollToSection(sponsorsRef)}><StarIcon /></MuiLink>
                    <MuiLink onClick={() => scrollToSection(contactRef)}><ContactMailIcon /></MuiLink>
                    <RouterLink to="/faq"><HelpIcon /></RouterLink>
                </>
                )
            )}

            {location.pathname === '/faq' && (
                isMdUp ? (
                    <>
                        <RouterLink to = "/"><MuiLink onClick={() => scrollToSection(heroRef)}>acasa</MuiLink></RouterLink>
                        <RouterLink to = "/departamente"><MuiLink>departamente</MuiLink></RouterLink>
                        <RouterLink to = "/evenimente"><MuiLink>evenimente</MuiLink></RouterLink>
                        <RouterLink to = "/conducere"><MuiLink>conducere</MuiLink></RouterLink>
                        <RouterLink to = "/sponsori"><MuiLink>sponsori</MuiLink></RouterLink>
                        <RouterLink to = "/contact"><MuiLink>contact</MuiLink></RouterLink>
                        <RouterLink to="/faq"><MuiLink>faq</MuiLink></RouterLink>
                    </>
                ) : (
                    <>
                    <RouterLink to = "/"><MuiLink onClick={() => scrollToSection(heroRef)}><HomeIcon /></MuiLink></RouterLink>
                    <RouterLink to = "/departamente"><MuiLink onClick={() => scrollToSection(departmentsRef)}><BusinessIcon /></MuiLink></RouterLink>
                    <RouterLink to = "/evenimente"><MuiLink onClick={() => scrollToSection(eventsRef)}><EventIcon /></MuiLink></RouterLink>
                    <RouterLink to = "/conducere"><MuiLink onClick={() => scrollToSection(leadershipRef)}><PeopleIcon /></MuiLink></RouterLink>
                    <RouterLink to = "/sponsori"><MuiLink onClick={() => scrollToSection(sponsorsRef)}><StarIcon /></MuiLink></RouterLink>
                    <RouterLink to = "/contact"><MuiLink onClick={() => scrollToSection(contactRef)}><ContactMailIcon /></MuiLink></RouterLink>
                    <RouterLink to="/faq"><HelpIcon /></RouterLink>
                </>
                )
            )}


        </Stack>
    );
};

export default NavBar;
