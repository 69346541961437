import { createSvgIcon } from "@mui/material";

const LsacLogo = createSvgIcon(
  <svg
    width={449}
    height={514}
    viewBox="0 0 449 514"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="logoGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" stopColor="#B7DEED" />
        <stop offset="50%" stopColor="#71CEEF" />
        <stop offset="100%" stopColor="#1773E2" />
      </linearGradient>
    </defs>
    <path
      d="M134.772 381.495C137.08 383.437 139.886 385.11 143.006 386.425C146.168 387.754 149.53 388.65 153.006 389.038C156.396 389.412 159.886 389.233 163.376 388.426C166.823 387.664 170.342 386.2 173.875 384.064L246.71 338.702L230.556 325.169C224.758 320.375 218.961 317.805 212.764 317.432C206.468 317.059 200.228 318.717 193.661 322.6L118.846 368.202L134.772 381.495Z"
      fill="url(#logoGradient)"
    />
    <path
      d="M432.887 131.277C430.579 129.335 427.773 127.662 424.653 126.347C421.49 125.018 418.129 124.122 414.653 123.734C411.262 123.36 407.772 123.539 404.282 124.346C400.835 125.108 397.316 126.572 393.784 128.707L0.175995 368.695L16.33 382.227C22.1278 387.022 27.9255 389.591 34.1222 389.965C40.4185 390.338 46.6579 388.68 53.2249 384.796L448.827 144.57L432.887 131.277Z"
      fill="url(#logoGradient)"
    />
    <path
      d="M313.825 133.666C311.518 131.725 308.711 130.052 305.592 128.722C302.429 127.393 299.067 126.497 295.592 126.108C292.201 125.735 288.711 125.914 285.221 126.721C281.788 127.483 278.255 128.946 274.723 131.082L205.876 175.086L222.03 188.618C227.827 193.413 233.625 195.982 239.822 196.356C246.118 196.729 252.358 195.071 258.925 191.188L329.751 146.945L313.825 133.666Z"
      fill="url(#logoGradient)"
    />
    <path
      d="M308.968 344.228L368.128 379.987C367.031 380.973 365.835 381.854 364.567 382.571L147.556 513.969V461.87C147.556 449.547 153.824 438.15 164.01 431.981L308.968 344.228Z"
      fill="url(#logoGradient)"
    />
    <path
      d="M301.033 -0.00170898V52.0824C301.033 64.4201 294.751 75.8168 284.566 81.9707L80.4617 205.362C69.5927 197.267 62.9687 183.913 62.9687 169.664C62.9687 153.906 71.0314 139.313 84.023 131.381L301.033 -0.00170898Z"
      fill="url(#logoGradient)"
    />
    <path
      d="M220.007 218.283L160.576 254.504L147.585 246.677V246.438L84.023 207.946C82.7552 207.23 81.5728 206.333 80.4902 205.377L139.608 169.604L220.007 218.283Z"
      fill="url(#logoGradient)"
    />
    <path
      d="M385.593 344.303C385.593 358.538 378.983 371.876 368.114 380.002L297.992 337.619L227.871 295.236L287.401 259.075L301.019 267.335V267.574L364.524 306.021C377.53 313.937 385.593 328.545 385.593 344.303Z"
      fill="url(#logoGradient)"
    />
  </svg>,
  'LsacLogo'
);

export default LsacLogo;