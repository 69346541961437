import React, { forwardRef, lazy } from "react";
import { Grid2, Box, Stack, Typography, Button } from "@mui/material";
import Boboci from "./../../../shared/images/events/Balul_Bobocilor.webp";

const CarouselSingle = lazy(() => import("./../Carousel/components/CarouselSingleCostum"));

const NextEvent = forwardRef<HTMLDivElement>((props, ref) => (
  <Grid2
    container
    ref={ref}
    sx={{
      width: "100vw",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#03254c",
      padding: {
        xs: "32px",
        md: "64px 0px",
      },
      zIndex: '10'
    }}
  >
    <Grid2 size={{ xs: 12, md: 10 }} sx={{
      alignItems: 'center',
      gap: '64px',
      flexDirection: 'column',
      justifyContent: 'center',
      display: 'flex'
    }}>
      <Typography variant="h1" sx={{ textAlign: "center", fontSize: 'clamp(1.25rem, 10vw, 4rem)' }}>
        Următorul eveniment
      </Typography>
      <CarouselSingle
        title="Balul Bobocilor 2024"
        subtitle="Află mai multe informații acesând unul dintre formularele de mai jos:"
        data="22 Noiembrie 2024"
        redirect="/"
        image={Boboci}
      />
      <Box sx={{ display: { xs: "flex", md: "none" }, flexDirection: "column" }} alignItems="center" justifyContent="space-between" width="100%">
        <Button className = "light_orange" variant="contained" href="/inscrieri_bal" sx={{ backgroundColor: "#ff6e00", flexGrow: 1, width: "100%", margin: "10px 0" }}>
          <Typography
            sx={{
              color: "#D0EFFF",
              fontSize: "16px",
              fontWeight: "bold",
              textDecoration: "none",
            }}

          >
            Înscrie-te
          </Typography>
        </Button>
        <Button className = "light_blue" variant="contained" href="/inscrieri_bal_mentor" sx={{backgroundColor: '#196EB4', flexGrow: 1, width: "100%", margin: "10px 0" }}>
          <Typography
            sx={{
              color: "#D0EFFF",
              fontSize: "16px",
              fontWeight: "bold",
              textDecoration: "none",
            }}
          >
            Înscrie-te ca mentor
          </Typography>
        </Button>
        <Button className = "light_blue" variant="contained" href="/inscrieri_bal_prezentator" sx={{ backgroundColor: '#196EB4', flexGrow: 1, width: "100%", margin: "10px 0" }}>
          <Typography
            sx={{
              color: "#D0EFFF",
              fontSize: "16px",
              fontWeight: "bold",
              textDecoration: "none",
            }}
          >
            Înscrie-te ca prezentator
          </Typography>
        </Button>
      </Box>
    </Grid2>

  </Grid2>
));

export default NextEvent;