import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
    allVariants: {
      color: "white",
      fontWeight: "bold",
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins, sans-serif",
          color: "white",
          textTransform: "uppercase",
          textDecoration: "none",
          fontWeight: "bold",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          "--TextField-brandBorderColor": "#D0EFFF",
          "--TextField-brandBorderHoverColor": "white",
          "--TextField-brandBorderFocusedColor": "#03254C",
          background: '#D0EFFF7F',
          fontWeight: "bold",
          "& label.Mui-focused": {
            color: "#03254C",
            fontWeight: 'bold',
            background: 'transparent',
            borderRadius: '4px'
          },
          "& label": {
            color: '#03254C',
            fontWeight: 'bold',
            background: 'transparent',
            borderRadius: '4px'
          },
          color: "red",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--TextField-brandBorderHoverColor)",
          },
          fieldset: {
            borderRadius: '20px'
          }
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "var(--TextField-brandBorderColor)",
        },
        root: {
          color: "white",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--TextField-brandBorderHoverColor)",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--TextField-brandBorderFocusedColor)",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: "#cccc",
          fontWeight: "bold",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#2185D5",
          fontWeight: "600",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "#CCC",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#F3F3F3",
          "&.Mui-checked": {
            color: "#2185D5",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&::before, &::after": {
            borderBottom: "2px solid var(--TextField-brandBorderColor)",
          },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
          },
          "&.Mui-focused:after": {
            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
          },
          color: "#CCC",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&::before": {
            borderBottom: "2px solid var(--TextField-brandBorderColor)",
          },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
          },
          "&.Mui-focused:after": {
            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
          },
          color: "#F3F3F3",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          fill: "#2185D5",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: "#303841",
          color: "#F3F3F3",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#CCC",
          "&.Mui-focused": {
            color: "#F3",
          },
        },
        standard: {
          color: "#F3f3f3",
          fontWeight: "400",
        },
      },
    },
  },
});

export default theme;
