import React, { forwardRef, lazy, Suspense } from "react";

import { Stack, Typography, Grid2 } from "@mui/material";

import Radu from "./../../../shared/images/prezentare/bord/Radu.jpg";
import Manea from "./../../../shared/images/prezentare/bord/Vicepresedinte_Manea_TudorDumitru.jpg";
import Costi from "./../../../shared/images/prezentare/bord/Secretar_Butco_DumitruConstantin.jpg";
import C1 from "./../../../shared/images/prezentare/cenzor/CENZOR_Munteanu_MariaGenoveva.jpeg";
import C2 from "./../../../shared/images/prezentare/cenzor/CENZOR_Ciobanu_AnaMaria.jpg";

import PRD from "./../../../shared/images/prezentare/director/PR_Lostun_Serban-Ilie.png";
import PRA from "./../../../shared/images/prezentare/adjunct/PR_Racovita_Maria.jpg";

import FRD from "./../../../shared/images/prezentare/director/FR_Stefanache_Elisa.jpeg";
import FRA from "./../../../shared/images/prezentare/adjunct/FR-Matei-Ioana.jpeg";

import ITD from "./../../../shared/images/prezentare/director/IT_Roman_Vlad-Adrian.png";
import ITA from "./../../../shared/images/prezentare/adjunct/IT_Nita_Dennis.png";

import HRD from "./../../../shared/images/prezentare/director/HR_Roca_Cristian.jpg";
import HRA from "./../../../shared/images/prezentare/adjunct/HR_Afrăsinei_Anamaria.jpg";

import DD from "./../../../shared/images/prezentare/director/DESIGN_Lesan_Vasile.jpg";
import DA from "./../../../shared/images/prezentare/adjunct/Design_Catanescu_George.jpg";

const MemberContainer = lazy(() => import("./components/MemberContainer"));
const Directors = lazy(() => import("./components/Directors"));

const Team = forwardRef<HTMLDivElement>((props, ref) => (
  <Grid2
    ref={ref}
    container
    sx={{
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "84px",
    }}
  >
    <Grid2
      size={{ xs: 12, md: 10 }}
      sx={{
        backgroundColor: "rgb(17, 103, 177, 0.4)",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        borderRadius: "40px",
        flexDirection: "column",
        alignItems: "center",
        gap: "32px",
        paddingY: "32px",
        textAlign: "center",
      }}
    >
      <Typography variant="h1" sx={{ fontSize: "clamp(1.25rem, 10vw, 3rem)" }}>
        Meet our <span style={{ color: "#D0EFFF", opacity: "0.4" }}>#</span>TEAM
      </Typography>

      {/* Lazy loading pentru componente */}
      <Suspense fallback={<div>Loading board...</div>}>
        <Stack
          sx={{
            backgroundColor: "#03254C",
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            paddingY: "16px",
          }}
        >
          <Typography variant="h3" sx={{ fontSize: "clamp(1rem, 5vw, 2.5rem)" }}>
            Bordul De Conducere
          </Typography>
        </Stack>

        <Stack
          sx={{
            width: "80%",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "16px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MemberContainer name="Buțco Dumitru-Constantin" role="Secretar" image={Costi} />
          <MemberContainer name="Munteanu Radu Ștefan" role="Președinte" image={Radu} />
          <MemberContainer name="Manea Tudor Dumitru" role="Vicepreședinte" image={Manea} />
          <MemberContainer name="Ciobanu Ana Maria" role="Cenzor" image={C2} />
          <MemberContainer name="Munteanu Maria Genoveva" role="Cenzor" image={C1} />
        </Stack>
      </Suspense>

      <Suspense fallback={<div>Loading department board...</div>}>
        <Stack
          sx={{
            backgroundColor: "#03254C",
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            paddingY: "16px",
          }}
        >
          <Typography variant="h3" sx={{ fontSize: "clamp(1rem, 5vw, 2.5rem)" }}>
            Bordul de Conducere al Departamentelor
          </Typography>
        </Stack>

        <Stack sx={{ width: { xs: "90%", sm: "70%", lg: "70%" }, rowGap: "32px" }}>
          <Directors
            nameDirector="Lostun Șerban-Ilie"
            nameAdjunct="Racoviță Maria"
            pozaAdjunct={PRA}
            pozaDirector={PRD}
            par={false}
            departament="PR"
          />
          <Directors
            nameDirector="Ștefanache Elisa"
            nameAdjunct="Matei Ioana Denisa"
            pozaAdjunct={FRA}
            pozaDirector={FRD}
            par={true}
            departament="FR"
          />
          <Directors
            nameDirector="Roman Vlad Adrian"
            nameAdjunct="Nita Dennis Alexandru"
            pozaAdjunct={ITA}
            pozaDirector={ITD}
            par={false}
            departament="IT"
          />
          <Directors
            nameDirector="Roca Cristian"
            nameAdjunct="Afrăsinei Anamaria"
            pozaAdjunct={HRA}
            pozaDirector={HRD}
            par={true}
            departament="HR"
          />
          <Directors
            nameDirector="Leșan Vasile"
            nameAdjunct="Cătănescu George"
            pozaAdjunct={DA}
            pozaDirector={DD}
            par={false}
            departament="DESIGN"
          />
        </Stack>
      </Suspense>
    </Grid2>
  </Grid2>
));

export default Team;
