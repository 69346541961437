import React, { forwardRef } from "react";
import { Stack, Typography } from "@mui/material";
import Carousel from "./components/Carousel";

const Evenimente = forwardRef<HTMLDivElement>((props, ref) => (
  <Stack
    ref={ref}
    sx={{
      width: "100vw",
      alignItems: "center",
      gap: {
        xs: "16px",
        md: 'unset'
      },
      justifyContent: "center",
    //   background: "green",
      height: "max-content",
      overflowX: 'hidden',
      marginTop: '64px'
    }}
  >
    <Typography
      variant="h1"
      sx={{ fontSize: "clamp(1.25rem, 10vw, 4.5rem)", zIndex: "-1" }}
    >
      Evenimente
    </Typography>
    <Carousel />
  </Stack>
));

export default Evenimente;
