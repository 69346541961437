import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';
import routes from './shared/config/routes';
import { ThemeProvider } from '@emotion/react';
import theme from '../src/shared/theme/theme';

const App: React.FC = () => {
  const routing = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      {routing}
    </ThemeProvider>
  );
};

export default App;
