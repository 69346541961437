import React, { useState } from "react";
import {
  Stack,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  ThemeProvider,
  FormGroup,
} from "@mui/material";
import theme from "../../../../../shared/theme/theme";

const Form: React.FC = () => {
  const [selectedCamin, setSelectedCamin] = useState<string[]>([]);
  const [selectedAcord, setSelectedAcord] = useState<string[]>([]);

  const handleAcordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked && selectedAcord.length < 1) {
      setSelectedAcord([...selectedAcord, value]);
    } else {
      setSelectedAcord(selectedAcord.filter((option) => option !== value));
    }
  };

  const handleCaminChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked && selectedCamin.length < 1) {
      setSelectedCamin([...selectedCamin, value]);
    } else {
      setSelectedCamin(selectedCamin.filter((option) => option !== value));
    }
  }


  const camin = ["DA", "NU"];
  const acord = ["DA", "NU"];

  return (
    <ThemeProvider theme={theme}>
      <Stack sx={{ gap: "16px", width: "100%", display: "flex", flexDirection: "column", height: "100%", }}>
        <TextField required name="nume" label="1. Nume" fullWidth />
        <TextField required name="prenume" label="2. Prenume" fullWidth />
        <TextField required name="email" label="3. E-mail" fullWidth />
        <Typography>
          4. Camin?
        </Typography>
        <FormGroup>
          {camin.map((option) => (
            <FormControlLabel
              key={option}
              control={<Checkbox checked={selectedCamin.includes(option)} onChange={handleCaminChange} name="camin" value={option} />}
              label={option}
            />
          ))}
        </FormGroup>
        <TextField required name="prezentator" label="5. De ce îți dorești să fii prezentator?" fullWidth />

        <Typography>
          6. Este în regulă să apari în materialele ce vor apărea în urma evenimentului?
        </Typography>
        <FormGroup>
          {acord.map((option) => (
            <FormControlLabel
              key={option}
              control={<Checkbox checked={selectedAcord.includes(option)} onChange={handleAcordChange} name="acord" value={option} />}
              label={option}
            />
          ))}
        </FormGroup>
      </Stack>
    </ThemeProvider >
  );
};

export default Form;
