import React from "react";
import { Grid2, Box, Typography, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { LsacLogo } from "./../../../../shared/icons";
import Lsac from "./../../../../shared/images/3d_lsac.png";

const HomeComp: React.FC = () => (
  <Grid2
    container
    sx={{
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: {
        xs: "center",
        md: "flex-end"
      },
      display: "flex",
      position: "relative",
    }}
  >
    <Stack
      component={"img"}
      src={Lsac}
      sx={{
        width: "auto",
        height: {
          xs: '0%',
          sm: '0%',
          md: "80%",
          lg: "95%",
          xl: "110%",
        },
        position: "absolute",
        // background: 'red',
        transform: "rotate(-10deg) translate(-30%, 10%)",
        // padding: "16px 16px",
        /*justifyItems:"center",
          justifyContent:"center",
          justifySelf:"center",
          alignItems:"center",
          alignContent:"center" */
        bottom: "0",
        left: "0",
        zIndex: '0'
      }}
    ></Stack>
    <Grid2 size={{ xs: 12, md: 8}} sx={{zIndex: '2'}}>
      <Stack
        sx={{
          flexDirection: "column",
          alignItems: {
            xs: "center",
        md: "flex-end"
          },
          justifyContent: "center",
          textAlign: "center",
          gap: "32px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Typography
            sx={{
              width: "100%",
              fontSize: {
                xs: "clamp(2rem, 10vw, 3rem)",
                // md: "clamp(2rem, 60vw, 4rem)",
              },
              textAlign: {
                xs: "center",
                md: "right",
              },
              filter: 'drop-shadow(5px 5px 10px #03254C)'
            }}
          >
            Liga Studenților de la Facultatea de Automatică și Calculatoare
          </Typography>
        </Box>
        <Stack
          sx={{
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            alignItems: 'center',
            width: 'max-content',
            '>*': {
                width: {
                  xs: '100%',
                  md: 'max-content'
              },
            },
            gap: '8px',
          }}
        >
          {/* <Button
            sx={{
              width: "max-content",
              height: "auto",
              fontSize: { xs: "1rem" },
              backgroundColor: "#1976d2",
              borderRadius: "24px",
              padding: "12px 24px",
              transition: "all 0.2s ease-in-out",
              color: 'white',
              "&:hover": {
                backgroundColor: "#FFF",
                color: "#1976d2",
              },
            }}
            disabled
          >
            Inscrie-te în LSAC
          </Button> */}
          <Button
            sx={{
              width: "max-content",
              height: "auto",
              fontSize: { xs: "1rem" },
              backgroundColor: "#ffffff",
              borderRadius: "24px",
              padding: "12px 24px",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                backgroundColor: "#1976d2",
                color: "white",
              },
            }}
            href="https://redirectioneaza.ro/lsaciasi/"
          >
            Redirecționați 3.5%
          </Button>
          
        </Stack>
      </Stack>
    </Grid2>

    {/* <Typography
    component="div"
    sx={{
      background: 'linear-gradient(to right, #B7DEED 0%, #71CEEF 0%, #1773E2 94%, #B7DEED 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      fontSize: {md: '3.2rem', xs: '1.4rem'},
      textAlign: 'justify',
      fontWeight: '0',
      paddingX: '16px',
      marginBottom: 'auto',
      maxWidth: '90%',
    }}
  >
    Liga Studenților din Facultatea de Automatică și Calculatoare

  </Typography> */}
  </Grid2>
);

export default HomeComp;
