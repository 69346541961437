import React, { useState } from "react";
import {
  Stack,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  ThemeProvider,
  FormGroup,
} from "@mui/material";
import theme from "../../../shared/theme/theme";

const Form: React.FC = () => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedZileInterviu, setSelectedZileInterviu] = useState<string[]>([]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked && selectedOptions.length < 2) {
      setSelectedOptions([...selectedOptions, value]);
    } else if (!event.target.checked) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };

  const handleZileInterviuChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedZileInterviu([...selectedZileInterviu, value]);
    } else {
      setSelectedZileInterviu(selectedZileInterviu.filter((option) => option !== value));
    }
  };

  const departamente = ["HR", "PR", "FR", "IT", "DESIGN"];
  const zileInterviu = ["Sâmbătă 05.10", "Duminică 06.10", "Luni 07.10"];

  return (
    <ThemeProvider theme={theme}>
      <Stack sx={{  gap: "16px", width: "100%", display: "flex", flexDirection: "column", height: "100%", }}>
        <TextField required name="nume" label="1. Nume" fullWidth />
        <TextField required name="prenume" label="2. Prenume" fullWidth />
        <TextField required name="email" label="3. E-mail" fullWidth />
        <TextField required name="telefon" label="4. Telefon" fullWidth />
        <Stack gap={'16px'}>
          <Typography>
            Din ce departament doriți să faceți parte? <span style={{ fontStyle: "italic", color: "#CCC" }}>(Maxim 2)</span>
          </Typography>
          <FormGroup>
            {departamente.map((option) => (
              <FormControlLabel
                key={option}
                control={<Checkbox checked={selectedOptions.includes(option)} onChange={handleCheckboxChange} name="departamente" value={option} />} 
                label={option}
              />
            ))}
          </FormGroup>
        </Stack>
        <Stack>
          <Typography>
            Alege zilele pentru interviu <span style={{ fontStyle: "italic", color: "#CCC" }}>(Poți selecta mai multe)</span>
          </Typography>
          <FormGroup>
            {zileInterviu.map((option) => (
              <FormControlLabel
                key={option}
                control={<Checkbox checked={selectedZileInterviu.includes(option)} onChange={handleZileInterviuChange} name="zileInterviu" value={option} />}
                label={option}
              />
            ))}
          </FormGroup>
        </Stack>
        <TextField required name="oraDisponibilitate" label="7. Preferințe oră interviu" fullWidth />
        <TextField name="pasiune" label="8. Daca ai pasiuni, enumără-le mai jos" fullWidth />
      </Stack>
    </ThemeProvider>
  );
};

export default Form;
