import { createSvgIcon } from "@mui/material";

const ArrowIcon = createSvgIcon(
    <svg
    width={7}
    height={12}
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 1L1 6"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 6L6 11"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'ArrowIcon'
)

export default ArrowIcon