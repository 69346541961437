import React, {forwardRef} from "react";
import { Grid2, Typography } from '@mui/material'
import HeroSectionBackground from './../../../shared/images/poza_lsac_site.jpg'

import HomeComp from "./components/HomeComp";

const HeroSection = forwardRef<HTMLDivElement>((props, ref) => (
    <Grid2
        container
        ref={ref}
        sx={{
            width: '100vw',
            height: '100vh',
            background: `linear-gradient(transparent 0%, #03254CA8 100%), url(${HeroSectionBackground})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'unset',
            padding: {
                xs: '0 16px',
                md: '0'
            }
        }}
    >
        <Grid2
            size={{xs: 12, md: 10}}
            sx={{
                height: '100%',
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex'
            }}
        >
        <HomeComp />
        </Grid2>
    </Grid2>
));

export default HeroSection