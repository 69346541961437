import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Fab, Zoom } from "@mui/material";

const BTT = () => {
      const [showButton, setShowButton] = useState(false);
    
      const handleScroll = () => {
        if (window.scrollY > 300) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      };
    
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", 
        });
      };
    
      useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);
    
      return (
        <Zoom in={showButton}>
          <Fab
            color="default"
            size="large"
            onClick={scrollToTop}
            aria-label="scroll back to top"
            sx={{
              position: "fixed",
              bottom: 20,
              right: 50,
            }}
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </Zoom>
      );
    };
    
export default BTT;
