import React, { forwardRef } from "react";
import { Grid2, Box, Stack, Typography } from "@mui/material";
import Design from "./../../../shared/images/departamente/DESIGN.png";
import Fr from "./../../../shared/images/departamente/FR.png";
import Hr from "./../../../shared/images/departamente/HR.png";
import It from "./../../../shared/images/departamente/IT.png";
import Pr from "./../../../shared/images/departamente/PR.png";
const images = [Design, Fr, Hr, It, Pr];

const Departamente = forwardRef<HTMLDivElement>((props, ref) => (
  <Grid2
    container
    ref={ref}
    sx={{
      width: "100vw",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#03254c",
      padding: {
        xs: "32px",
        md: "64px 0px",
      },
      zIndex: '10'
    }}
  >
    <Grid2 size={{ xs: 12, md: 10 }} sx={{
      alignItems: 'center',
      gap: '64px',
      flexDirection: 'column',
      justifyContent: 'center',
      display: 'flex'
    }}>
      <Typography variant="h1" sx={{ fontSize: 'clamp(1.25rem, 10vw, 4rem)' }}>
        Departamente
      </Typography>
      <Stack
        sx={{
          flexDirection: 'row',
          gap: '32px',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {images.map((image, index) => (
          <Box
            key={index}
            component="img"
            src={image}
            alt={`image-${index}`}
            sx={{
              width: {
                xs: "calc(50% - 32px)",
                sm: "calc(33.3% - 32px)",
                md: "calc(20% - 32px)",

              },
              height: "auto",
              transition: 'all 0.2s ease-in-out',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.05) !important'
              }
            }}
          />
        ))}
      </Stack>
    </Grid2>
  </Grid2>
));

export default Departamente;
