import React, { useState, forwardRef } from "react";
import {
  Stack,
  Grid2,
  TextField,
  Button,
  Box,
  Divider,
  Typography,
  InputLabelProps,
} from "@mui/material";

import Radu from "./../../../shared/images/prezentare/bord/Radu.jpg";
import Manea from "./../../../shared/images/prezentare/bord/Vicepresedinte_Manea_TudorDumitru.jpg";
import Costi from "./../../../shared/images/prezentare/bord/Secretar_Butco_DumitruConstantin.jpg";
import C1 from "./../../../shared/images/prezentare/cenzor/CENZOR_Munteanu_MariaGenoveva.jpeg";
import C2 from "./../../../shared/images/prezentare/cenzor/CENZOR_Ciobanu_AnaMaria.jpg";

import { SendMsg, ArrowIcon } from "../../../shared/icons";
import ArrowButton from "./components/ArrowButton";

interface InputFieldProps {
  label: string;
  name: string;
  variant?: "outlined" | "filled" | "standard";
  fullWidth: boolean;
  multiline?: boolean;
  rows?: number;
  isDetalii?: boolean;
}

interface StaffCardProps {
  name: string;
  role: string;
  mail: string;
  image: string;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  variant = "outlined",
  fullWidth = true,
  multiline = false,
  rows = 0,
  name,
  isDetalii = false,
}) => {
  return (
    <TextField
      label={label}
      variant={variant}
      fullWidth={fullWidth}
      multiline={multiline}
      name={name}
      rows={rows}
      sx={{
        borderRadius: "20px",
        //   "& label": {
        //     fontSize: "16px",
        //     color: isDetalii ? "rgba(3, 37, 76, 0.7)" : "rgba(3, 37, 76, 0.5)",
        //     fontWeight: "bold",
        //     top: "50%",
        //     left: "0%",
        //     transform: "translate(14px, -50%) scale(1)", // Poziționare inițială
        //     transition: "all 0.2s ease-in-out",
        //     "&.Mui-focused": {
        //       color: "rgba(3, 37, 76, 1)", // Culoare pe focus
        //       top: "-6px", // Poziționare ridicată
        //       transform: "translate(14px, -50%) scale(0.85)", // Diminuare pe focus
        //     },
        //   },
        //   "& .MuiOutlinedInput-root": {
        //     borderRadius: "16px",
        //     backgroundColor: "rgba(208, 239, 255, 0.5)",
        //     "& fieldset": {
        //       border: "1px solid",
        //       borderColor: "#D0EFFF",
        //     },
        //     "&:hover fieldset": {
        //       border: "2px solid",
        //       borderColor: "#D0EFFF",
        //     },
        //     "&.Mui-focused fieldset": {
        //       border: "2px solid rgba(3, 37, 76, 1)", // Schimbare culoare border pe focus
        //     },
        //   },
        // }}
      }}
    />
  );
};

const StaffCard: React.FC<StaffCardProps> = ({ name, role, mail, image }) => (
  <Box
    sx={{
      backgroundColor: "rgba(42, 157, 244, 0.5)",
      width: "100%",
      border: "2px solid",
      borderColor: "rgba(42, 157, 244, 1)",
      borderRadius: "24px",
      padding: "16px",
      display: "flex",
    }}
  >
    <Box
      sx={{
        display: "flex",
        direction: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Stack
        sx={{
          alignItems: "flex-start",
          justifyContent: "center",
          textAlign: "flex-start",
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: { xs: "1.25rem" } }}>
          {name}
        </Typography>
        <Typography
          sx={{
            fontStyle: "italic",
            fontWeight: "400",
            fontSize: { xs: "1rem" },
          }}
        >
          {role}
        </Typography>
        <Typography sx={{ fontWeight: "200", fontSize: "1rem" }}>
          {mail}
        </Typography>
      </Stack>
      <Box
        sx={{
          background: `url(${image})`,
          backgroundSize: "cover",
          aspectRatio: "1/1",
          width: "clamp(96px, 5vw, 120px)",
          height: "auto",
          borderRadius: "50%",
          overflow: "hidden",
          border: "2px solid rgba(42, 157, 244, 1)",
        }}
      ></Box>
    </Box>
  </Box>
);

const Contact = forwardRef<HTMLDivElement>((props, ref) => {
  const [openContact, setOpenContact] = useState(false);

  const handleView = () => {
    setOpenContact(!openContact);
  };

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);

    const data = {
      email: formData.get("e-mail")?.toString() || "",
      nume: formData.get("nume")?.toString() || "",
      prenume: formData.get("prenume")?.toString() || "",
      telefon: formData.get("telefon")?.toString() || "",
      detalii: formData.get("detalii")?.toString() || "",
    };

    console.log("Date trimise:", data);

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbx0X2iB3HSnxaQVP9N4cCSu52p0hRaz5PsJS7Nx2xWFqE8CwFXrzAyiXQVLBK3O-bz1KQ/exec",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(data).toString(),
        }
      );

      const result = await response.json();
      if (result.result === "success") {
        alert("Mesaj trimis cu succes!");
      } else {
        alert("A apărut o eroare!");
      }
    } catch (error) {
      console.error("Eroare la trimiterea datelor:", error);
      alert("A apărut o eroare la trimiterea datelor!");
    }
  };

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    handleSubmit(event);
  };

  return (
    <Grid2
      ref={ref}
      container
      sx={{
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "64px",
      }}
    >
      <Grid2
        size={{ xs: 12, md: 10 }}
        sx={{
          backgroundColor: "rgba(17, 103, 177, 0.4)",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          borderRadius: "40px",
          flexDirection: "column",
          gap: "24px",
          padding: "16px",
          overflow: "hidden",
          position: "relative",
          border: "2px solid #1167B1",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "clamp(1.25rem, 8vw, 2.5rem)" },
            alignSelf: { xs: "center", md: "center" },
            textAlign: "center",
            paddingY: "10px",
            paddingX: "3px",
          }}
        >
          Contactează-ne!
        </Typography>
        <Divider
          sx={{
            borderColor: "#D0EFFF",
            borderWidth: "1px",
            borderRadius: "20px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            gap: "16px",
          }}
        >
          <Stack
            sx={{
              width: { xs: "100%", md: "45vw" },
              height: "max-content",
            }}
          >
            <Box
              component="form"
              onSubmit={onFormSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
              }}
              noValidate
              autoComplete="off"
            >
              <InputField
                label="Nume"
                name="nume"
                variant="outlined"
                fullWidth
              />
              <InputField
                label="Prenume"
                name="prenume"
                variant="outlined"
                fullWidth
              />
              <InputField
                label="E-mail"
                name="e-mail"
                variant="outlined"
                fullWidth
              />
              <InputField
                label="Telefon"
                name="telefon"
                variant="outlined"
                fullWidth
              />
              <InputField
                name="detalii"
                label="Detalii"
                variant="outlined"
                fullWidth
                multiline={true}
                rows={8}
                isDetalii={true}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  background: "#1167B17A",
                  borderRadius: "20px",
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  border: "2px solid #1167B1",
                  fontSize: {
                    xs: "clamp(1.3rem, 30vw, 1rem)",
                    md: "clamp(1rem, 30vw, 1.5rem)",
                  },
                  alignSelf: "center",
                  marginTop: "16px",
                  width: "175px",
                  height: "60px",
                }}
              >
                Trimite
              </Button>
            </Box>
          </Stack>
          {/* <Stack sx={{ width: "6%" }}></Stack> */}
          <Stack
            sx={{
              width: { xs: "100%", md: "45vw" },
              gap: "16px",
              zIndex: "2",
              overflow: "auto",
              padding: {
                xs: "16px",
                md: "unset",
              },
              height: {
                xs: "100%",
                md: "unset",
              },
              position: {
                xs: "absolute",
                md: "relative",
              },
              right: {
                xs: openContact ? "50%" : "-500%",
                md: "unset",
              },
              top: {
                xs: "50%",
                md: "unset",
              },
              transform: {
                xs: openContact ? "translate(50%, -50%)" : "translate(0, -50%)",
                md: "unset",
              },
              background: {
                xs: "#03254CCC",
                md: "unset",
              },
              transition: "all 0.2s ease-in-out",
            }}
          >
            <StaffCard
              name="Munteanu Radu Ștefan"
              role="Președinte LSAC"
              mail="radu-stefan.munteanu@student.tuiasi.ro"
              image={Radu}
            />
            <StaffCard
              name="Buțco Dumitru-Constantin"
              role="Secretar LSAC"
              mail="dumitrubutco11@gmail.com"
              image={Costi}
            />
            <StaffCard
              name="Manea Tudor Dumitru"
              role="Vicepreședinte Interne-Externe LSAC"
              mail="dumitru-tudor.manea@student.tuiasi.ro"
              image={Manea}
            />
            <StaffCard
              name="Munteanu Maria Genoveva"
              role="Cenzor LSAC"
              mail="mariagenoveva1503@gmail.com"
              image={C1}
            />
            <StaffCard
              name="Ciobanu Ana Maria"
              role="Cenzor LSAC"
              mail="ana.maria.c2609@gmail.com"
              image={C2}
            />
          </Stack>
        </Box>
        <Stack
          sx={{
            background: "#1167B1",
            borderRadius: "20px 0px 0px 20px",
            position: "absolute",
            top: "50%",
            right: "0",
            transform: "translate(0, -50%)",
            cursor: "pointer",
            padding: "12px",
            display: {
              xs: "flex",
              md: "none",
            },
            zIndex: "5",
          }}
          onClick={handleView}
        >
          <ArrowIcon
            sx={{
              width: "16px",
              height: "auto",
              transform: !openContact ? "rotate(0deg)" : "rotate(180deg)",
              transition: "all 0.2s ease-in-out",
            }}
          />
        </Stack>
        <SendMsg
          sx={{
            width: { xs: "50%" },
            height: "auto",
            position: "absolute",
            bottom: "-10%",
            left: "-5%",
            zIndex: "-1",
          }}
        />
      </Grid2>
    </Grid2>
  );
});

export default Contact;
