import React, { lazy, Suspense, useState, useEffect, startTransition } from "react";
import { Box, Typography } from "@mui/material";

import Boboci from "./../../../../shared/images/events/Balul_Bobocilor.webp";
import Campus from "./../../../../shared/images/events/Campus_Connect.webp";
import Gaudeamus from "./../../../../shared/images/events/Gudeamus.webp";
import ITM from "./../../../../shared/images/events/IT_Marathon.webp";
import LAN from "./../../../../shared/images/events/LAN_Party.webp";
import LSTORY from "./../../../../shared/images/events/LSAC_Story.webp";
import Olimpiada_AC from "./../../../../shared/images/events/Olimpiada_AC.webp";
import InfoIT from "./../../../../shared/images/events/Targ_InfoIT.webp";
import THunt from "./../../../../shared/images/events/Treasure_Hunt.webp";
import Recrutari from "./../../../../shared/images/events/recrutari.webp";

const CarouselSingle = lazy(() => import("./CarouselSingle"));

const Carousel: React.FC = () => {
  const [focusEventIndex, setFocusEventIndex] = useState(0);

  function mod(n: number, m: number): number {
    return ((n % m) + m) % m;
  }

  function handleEvent(i: number) {
    startTransition(() => {
      setFocusEventIndex((prev) => mod(prev + i, events.length));
    });
  }

  const [events, setEvents] = useState([
    {
      title: "Balul Bobocilor",
      subtitle: "Eveniment festiv pentru boboci.",
      data: "22 NOV",
      redirect: "/",
      image: Boboci,
    },
    {
      title: "Gaudeamus",
      subtitle: "Activități artistice pentru studenți creativi.",
      data: "TBA",
      redirect: "/",
      image: Gaudeamus,
    },
    {
      title: "Campus Connect",
      subtitle: "Familiarizarea studenților cu campusul și facultatea.",
      data: "28 SEP",
      redirect: "/",
      image: Campus,
    },
    {
      title: "Treasure Hunt",
      subtitle: "Vânătoare de comori prin campus.",
      data: "31 OCT",
      redirect: "/",
      image: THunt,
    },
    {
      title: "IT Marathon",
      subtitle: "Hackathon cu provocări IT.",
      data: "10 MAI",
      redirect: "/",
      image: ITM,
    },
    {
      title: "LAN Party",
      subtitle: "Gaming competițional pentru studenți.",
      data: "15 MAR",
      redirect: "/",
      image: LAN,
    },
    {
      title: "LSAC STORY",
      subtitle: "Activități de Crăciun și caritate.",
      data: "9 DEC",
      redirect: "/",
      image: LSTORY,
    },
    {
      title: "Olimpiada AC",
      subtitle: "Competiții sportive pentru caritate studențească.",
      data: "TBA",
      redirect: "/",
      image: Olimpiada_AC,
    },
    {
      title: "Târgul Info IT",
      subtitle: "Interacțiuni companii IT și studenți.",
      data: "17 MAI",
      redirect: "/",
      image: InfoIT,
    },
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleEvent(1);
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [focusEventIndex]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      position="relative"
      width="100%"
      sx={{
        height: "auto",
        aspectRatio: {
          xs: "16/8.5",
          md: "16/7.8",
          lg: "16/6",
        },
        alignItems: "center",
        justifyContent: "center",
        ">*": {
          width: {
            xs: "94% !important",
            sm: "90% !important",
            md: "70% !important",
            lg: "60% !important",
          },
          overflow: "hidden",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
        "& > :nth-of-type(1)": {
          transform: "translate(-8%, -35%) scale(0.8)",
          display: {
            xs: "none",
            md: "flex",
          },
        },
        "& > :nth-of-type(2)": {
          transform: "translate(-92%, -35%) scale(0.8)",
          display: {
            xs: "none",
            md: "flex",
          },
        },
      }}
    >
      <Box
        onClick={() => handleEvent(-1)}
        position="absolute"
        sx={{
          opacity: 0.5,
        }}
      >
        <CarouselSingle
          title={events[mod(focusEventIndex - 1, events.length)].title}
          subtitle={events[mod(focusEventIndex - 1, events.length)].subtitle}
          data={events[mod(focusEventIndex - 1, events.length)].data}
          redirect={events[mod(focusEventIndex - 1, events.length)].redirect}
          image={events[mod(focusEventIndex - 1, events.length)].image}
        />
      </Box>

      <Box
        onClick={() => handleEvent(1)}
        position="absolute"
        sx={{
          opacity: 0.5,
        }}
      >
        <CarouselSingle
          title={events[mod(focusEventIndex + 1, events.length)].title}
          subtitle={events[mod(focusEventIndex + 1, events.length)].subtitle}
          data={events[mod(focusEventIndex + 1, events.length)].data}
          redirect={events[mod(focusEventIndex + 1, events.length)].redirect}
          image={events[mod(focusEventIndex + 1, events.length)].image}
        />
      </Box>

      <Box
        position="absolute"
        sx={{
          transform: "translate(-50%, -50%)",
        }}
      >
        <Suspense
          fallback={
            <Box
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: "20px",
                background: "rgba(17, 103, 177, 0.4)",
                border: "2px solid #1167B1",
              }}
            >
              <Typography>Loading...</Typography>
            </Box>
          }
        >
          <CarouselSingle
            title={events[mod(focusEventIndex, events.length)].title}
            subtitle={events[mod(focusEventIndex, events.length)].subtitle}
            data={events[mod(focusEventIndex, events.length)].data}
            redirect={events[mod(focusEventIndex, events.length)].redirect}
            image={events[mod(focusEventIndex, events.length)].image}
          />
        </Suspense>
      </Box>
    </Box>
  );
};

export default Carousel;
