import React, { useState } from "react";
import {
  Stack,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  ThemeProvider,
  FormGroup,
} from "@mui/material";
import theme from "./../../../../../shared/theme/theme";

const Form: React.FC = () => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedSpecializare, setSelectedSpecializare] = useState<string[]>([]);
  const [selectedCamin, setSelectedCamin] = useState<string[]>([]);
  const [selectedTalent, setSelectedTalent] = useState<string[]>([]);
  const [selectedDeceniu, setSelectedDeceniu] = useState<string[]>([]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked && selectedOptions.length < 1) {
      setSelectedOptions([...selectedOptions, value]);
    } else if (!event.target.checked) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };

  const handleSpecializareChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked  && selectedSpecializare.length < 1) {
      setSelectedSpecializare([...selectedSpecializare, value]);
    } else {
      setSelectedSpecializare(selectedSpecializare.filter((option) => option !== value));
    }
  };

  const handleCaminChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked && selectedCamin.length < 1) {
      setSelectedCamin([...selectedCamin, value]);
    } else {
      setSelectedCamin(selectedCamin.filter((option) => option !== value));
    }
  }

  const handleTalentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked && selectedTalent.length < 1) {
      setSelectedTalent([...selectedTalent, value]);
    } else {
      setSelectedTalent(selectedTalent.filter((option) => option !== value));
    }
  }

  const handleDeceniuChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked && selectedDeceniu.length < 1) {
      setSelectedDeceniu([...selectedDeceniu, value]);
    } else {
      setSelectedDeceniu(selectedDeceniu.filter((option) => option !== value));
    }
  }

  const specializari = ["CTI", "IS"];
  const camin = ["DA", "NU"];
  const talente = ["Dans", "Teatru", "Pictură", "Nu știu", "Altul"];
  const deceniu = ["'50", "'70", "'80", "'00", "'10", "'20", "Altul"];

  return (
    <ThemeProvider theme={theme}>
      <Stack sx={{ gap: "16px", width: "100%", display: "flex", flexDirection: "column", height: "100%", }}>
        <TextField required name="nume" label="1. Nume" fullWidth />
        <TextField required name="prenume" label="2. Prenume" fullWidth />
        <TextField required name="email" label="3. E-mail" fullWidth />
        <Stack gap={'16px'}>
          <Typography>
            4. Specializare?
          </Typography>
          <FormGroup>
            {specializari.map((option) => (
              <FormControlLabel
                key={option}
                control={<Checkbox checked={selectedSpecializare.includes(option)} onChange={handleSpecializareChange} name="specializare" value={option} />}
                label={option}
              />
            ))}
          </FormGroup>
        </Stack>
        <TextField
          required
          name="descriere"
          label="5. Povestește-ne despre tine!"
          fullWidth
          multiline
          rows={4}
        />
        <Stack gap={'16px'}>
          <Typography>
            6. Camin?
          </Typography>
          <FormGroup>
            {camin.map((option) => (
              <FormControlLabel
                key={option}
                control={<Checkbox checked={selectedCamin.includes(option)} onChange={handleCaminChange} name="camin" value={option} />}
                label={option}
              />
            ))}
          </FormGroup>
          </Stack>

          <Stack gap={'16px'}>
            <Typography>
              7. Ce talent ai?
            </Typography>
            <FormGroup>
              {talente.map((option) => (
                <FormControlLabel
                  key={option}
                  control={<Checkbox checked={selectedTalent.includes(option)} onChange={handleTalentChange} name="talent" value={option} />}
                  label={option}
                />
              ))}
            </FormGroup>
          </Stack>

          {selectedTalent[0] === "Altul" && (
          <TextField required name="alt_talent" label="7.1. Ce alt talent ai?" fullWidth />
          )}
          <TextField required name="artistic" label="8. Preferințe moment artistic." fullWidth />
          <Stack gap={'16px'}>
            <Typography>
              9. Ce deceniu ți-ar plăcea să reprezinți?
            </Typography>
            <FormGroup>
              {deceniu.map((option) => (
                <FormControlLabel
                  key={option}
                  control={<Checkbox checked={selectedDeceniu.includes(option)} onChange={handleDeceniuChange} name="deceniu" value={option} />}
                  label={option}
                />
              ))}
            </FormGroup>
          </Stack>
          {selectedDeceniu[0] === "Altul" && (
          <TextField required name="alt_deceniu" label="9.1. Ce alt tip vrei?" fullWidth />
          )}

          <Typography sx={{fontWeight: '200'}}>
          Ți-ai ales deja partener? Atunci spune-ne numele și prenumele său!</Typography>
              
          <TextField required name="partener" label="10. Partener?" fullWidth />
            
          <Typography sx={{fontWeight: '200'}}>
            Ce așteptări ai de la acest eveniment? Eventual dacă ai putea să ne spui din ce motiv dorești să participi?
          </Typography>
          <TextField required name="asteptari" label="11. Așteptări?" fullWidth />
        </Stack>
    </ThemeProvider>
  );
};

export default Form;
