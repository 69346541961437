import React, { useState } from "react";
import { Grid2, Stack, Typography, Button, Alert } from "@mui/material";
import emailjs from "@emailjs/browser";
import Form from "./components/Form";
import Lsac from "./../../shared/images/3d_lsac.png";
import "./../../App.css";
import { useNavigate } from "react-router-dom";

const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();

  const emailElement = document.getElementById(
    "uniq_email"
  ) as HTMLInputElement | null;
  if (!emailElement) {
    console.error("Email element not found");
    return;
  }

  let params: Record<string, unknown> = {
    name: "LSAC",
    email: emailElement.value,
    subject: "Inscriere la recrutare LSAC",
    message: "Ai fost inscris cu succes la recrutare.",
  };

  emailjs
    .send("service_nhrwhua", "template_mfaq7ir", params, "pR-QpVvTJEdVeJeCR")
    .then((response) => {
      console.log("SUCCESS!", response.status, response.text);
    })
    .catch((error) => {
      console.error("FAILED...", error);
    });
};

const Inscrieri: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);

    const data = {
      nume: formData.get("nume")?.toString() || "",
      prenume: formData.get("prenume")?.toString() || "",
      email: formData.get("email")?.toString() || "",
      telefon: formData.get("telefon")?.toString() || "",
      departamente: formData.getAll("departamente").join(", ").toString(),
      zileInterviu: formData.getAll("zileInterviu").join(", ").toString(),
      oraDisponibilitate: formData.get("oraDisponibilitate")?.toString() || "",
      pasiuni: formData.get("pasiune")?.toString() || "",
    };

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycby6ZT63jEt3HHECHTE50gQl05xxjiwnpFctxcxsBgRiB-C21JrD5fzCSngTFfFD1Hk/exec",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(data).toString(),
        }
      );

      const result = await response.json();
      if (result.result === "success") {
        setSubmitSuccess(true);
        setIsSubmitted(true);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        alert("A apărut o eroare!");
      }
    } catch (error) {
      console.error("Eroare la trimiterea datelor:", error);
      alert("A apărut o eroare la trimiterea datelor!");
    }
  };

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await sendEmail(event);
      await handleSubmit(event);
    } catch (error) {
      console.error("Eroare în procesare:", error);
      alert("A apărut o eroare în trimiterea formularului!");
    }
  };

  return (
    <>
    <Grid2
      container
      sx={{
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        padding: "16px 0",
        overflowX: "hidden",
        backgroundSize: "cover",
      }}
    >
      <Grid2
        size={{ xs: 11, sm: 10, md: 7 }}
        sx={{
          position: "relative",
          height: "100%",
          overflow: "hidden",
          ">*:first-child": {
            gap: "16px",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "32px",
            background: "linear-gradient(45deg, #03254CA6 0%, #03254C 100%) ",
            backdropFilter: "blur(4px)",
            borderRadius: "20px",
            border: "2px solid #D0EFFF99",
            overflow: "auto",
            position: "relative",
          },
        }}
      >
        <form
          method="POST"
          id="form"
          name="google-sheet"
          onSubmit={onFormSubmit}
        >
          <Stack gap="8px">
            <Typography
              display="flex"
              variant="h4"
              fontWeight="800"
              color="#F3F3F3"
              flexDirection="row"
            >
              Inscrieri LSAC <Stack color="#D0EFFF">&nbsp;2024-2025</Stack>
            </Typography>
            <Form />
          </Stack>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitted}
            sx={{
              background: "#1167B17A",
              borderRadius: "20px",
              fontSize: "20px",
              fontFamily: "Poppins",
              fontWeight: "bold",
              border: "2px solid #1167B1",
            }}
          >
            Trimite
          </Button>
        </form>
        <Stack
          component={"img"}
          src={Lsac}
          sx={{
            width: "auto",
            height: "100%",
            transform: "rotate(-10deg) translate(-40px, 60px)",
            position: "absolute",
            bottom: "0",
            left: "0",
            zIndex: "-1",
          }}
        />
      </Grid2>
    </Grid2>
    {submitSuccess && (
      <Alert
        severity="success"
        sx={{
          marginTop: "16px",
          position: "fixed !important",
          zIndex: "200",
          left: "50%",
          transform: "translate(-50%, 0)",
          top: '0'
        }}
      >
        Înregistrat cu succes! Se reîncarcă pagina...
      </Alert>
    )}
    </>
  );
};

export default Inscrieri;
