import React, { useState } from "react";
import {
  Stack,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  ThemeProvider,
  FormGroup,
} from "@mui/material";
import theme from "./../../../../../shared/theme/theme";

const Form: React.FC = () => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedCamin, setSelectedCamin] = useState<string[]>([]);
  const [selectedDeceniu, setSelectedDeceniu] = useState<string[]>([]);
  const [selectedNumere, setSelectedNumere] = useState<string[]>([]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked && selectedOptions.length < 1) {
      setSelectedOptions([...selectedOptions, value]);
    } else if (!event.target.checked) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };

  const handleNumereChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedNumere([...selectedNumere, value]);
    } else {
      setSelectedNumere(selectedNumere.filter((option) => option !== value));
    }
    console.log(selectedNumere);
  }

  const handleCaminChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked && selectedCamin.length < 1) {
      setSelectedCamin([...selectedCamin, value]);
    } else {
      setSelectedCamin(selectedCamin.filter((option) => option !== value));
    }
  }

  const handleDeceniuChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked && selectedDeceniu.length < 1) {
      setSelectedDeceniu([...selectedDeceniu, value]);
    } else {
      setSelectedDeceniu(selectedDeceniu.filter((option) => option !== value));
    }
  }

  const camin = ["DA", "NU"];
  const numere = ["Dans", "Teatru", "Pictură", "Altul"];
  const deceniu = ["'50", "'70", "'80", "'00", "'10", "Altul"];

  return (
    <ThemeProvider theme={theme}>
      <Stack sx={{ gap: "16px", width: "100%", display: "flex", flexDirection: "column", height: "100%", }}>
        <TextField required name="nume" label="1. Nume" fullWidth />
        <TextField required name="prenume" label="2. Prenume" fullWidth />
        <TextField required name="email" label="3. E-mail" fullWidth />
        <Stack gap={'16px'}>
          <Typography>
            4. Camin?
          </Typography>
          <FormGroup>
            {camin.map((option) => (
              <FormControlLabel
                key={option}
                control={<Checkbox checked={selectedCamin.includes(option)} onChange={handleCaminChange} name="camin" value={option} />}
                label={option}
              />  
            ))}
          </FormGroup>
          </Stack><Stack gap={'16px'}>
          <Typography>
            5. La ce tip de număr ai dori să ajuți?
          </Typography>
          <FormGroup>
            {numere.map((option) => (
              <FormControlLabel
                key={option}
                control={<Checkbox checked={selectedNumere.includes(option)} onChange={handleNumereChange} name="numere" value={option} />}
                label={option}
              />
            ))}
          </FormGroup>
          </Stack>
          {selectedNumere.find(x => x === 'Altul') && (
          <TextField required name="alt_numere" label="7.1. Ce alt talent ai?" fullWidth />
          )}

          <Stack gap={'16px'}>
            <Typography>
              6. Ce deceniu ți-ar plăcea să reprezinți?
            </Typography>
            <FormGroup>
              {deceniu.map((option) => (
                <FormControlLabel
                  key={option}
                  control={<Checkbox checked={selectedDeceniu.includes(option)} onChange={handleDeceniuChange} name="deceniu" value={option} />}
                  label={option}
                />
              ))}
            </FormGroup>
          </Stack>
          {selectedDeceniu[0] === "Altul" && (
          <TextField required name="alt_deceniu" label="6.1. Ce altă idee ai?" fullWidth />
          )}

          <Typography sx={{fontWeight: '200'}}>
          Ai o idee de număr având în vedere opțiunile alese mai sus?</Typography>
          <TextField required name="idee" label="7. Ideea ta?" fullWidth />
            
        </Stack>
    </ThemeProvider>
  );
};

export default Form;
